<template>
  <div>
    <Modal v-model="showcode" :footer-hide="true" :mask-closable="false" width="360">
      <div class="box">
        <div id="qrcode3"></div>
        <div class="box-txt">
          <span>Scan and continue to communicate</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "qrCode",
  props: {
    url: String,
  },
  data() {
    return {
      showcode: false,
    };
  },
  created() {},
  methods: {
    open() {
      this.showcode = true;
      document.getElementById("qrcode3").innerHTML = "";
      let qrcode = new QRCode("qrcode3", {
        width: 234,
        height: 234,
        text: this.url,
        correctLevel: 3,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
#qrcode3 {
  width: 234px;
  height: 234px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.box { 
  text-align: center; 
  height: 325px; 
  padding-top: 25px;
  &-txt {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
  }
}
</style>
